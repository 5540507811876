<template>
  <ul class="b-pagination d-flex justify-content-end pagination pagination-md mb-0">
    <li
      v-if="pagination.to"
      class="page-item">
      <a href="javascript:void(0)" 
        aria-label="Previous" 
        v-on:click.prevent="pagination.current_page > 1 && changePage(1)" > 
        <span 
          aria-hidden="true" 
          class="page-link rounded-pill">«</span>
      </a>
    </li>
    <li v-for="(page, key) in pagesNumber" :key="key" class="page-item " :class="{'active': page == pagination.current_page}" >
      <a href="javascript:void(0)" v-on:click.prevent="changePage(page)" class="page-link rounded-pill text-xs">{{ page }}</a>
    </li>
    <li class="page-item">
      <a 
        v-if="pagination.to"
        href="javascript:void(0)" 
        aria-label="Next" 
        v-on:click.prevent="pagination.current_page < pagination.last_page && changePage(pagination.last_page)">
        <span aria-hidden="true" class="page-link rounded-pill">»</span>
      </a>
    </li>
  </ul>
</template>
<style scoped>
  ul.pagination a:hover {
    text-decoration: none !important;
  }
  .pagination .page-item .page-link {
    padding: 0.35rem 0.675rem !important;
    margin-left: 2px !important;
    margin-right: 2px !important;
  }
</style>
<script>
  export default {
      name: 'Paginate',
      props: {
      pagination: {
          type: Object,
          required: true
      },
      offset: {
          type: Number,
          default: 4
      }
    },
    computed: {
      pagesNumber() {
        if (!this.pagination.to) {
          return []
        }
        let from = this.pagination.current_page - this.offset
        if (from < 1) {
          from = 1
        }
        let to = from + (this.offset * 2)
        if (to >= this.pagination.last_page) {
          to = this.pagination.last_page
        }
        let pagesArray = [];
        for (let page = from; page <= to; page++) {
          pagesArray.push(page)
        }
          return pagesArray
      }
    },
    methods : {
      changePage(page) {
        this.pagination.current_page = page
        this.$emit('paginate', page)
      }
    }
  }
</script>